// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Teko:wght@300;400;500;600;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css');

// General
body {
  font-family: 'Teko', sans-serif;
}

h1 {
  margin-bottom: 25px;
  text-transform: uppercase;
  color: white;
  span {
    padding-left: 10px;
    padding-right: 10px;
    background: #e40000;
    opacity: 0.9;
  }
}

// Hero
.hero {
  background-image: url(/img/team_photo.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 300px;
  color: white;
  h1 {
    margin-top: 50px;
    font-size: 2rem;
    span {
      background: #e40000;
      opacity: 0.9;
    }
  }
  p {
    font-family: 'Open Sans', sans-serif;
  }
  button {
    opacity: 0.9;
    width: 160px;
    height: 60px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 50px;
    border: 0;
    background: #e40000;
    color: white;
    &:hover {
      background: white;
      color: black;
    }
  }
}

// Timetable
.bg-light-gray {
  background-color: #f7f7f7;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.bg-sky.box-shadow {
  box-shadow: 0px 5px 0px 0px #00a2a7;
}

.bg-orange.box-shadow {
  box-shadow: 0px 5px 0px 0px #af4305;
}

.bg-green.box-shadow {
  box-shadow: 0px 5px 0px 0px #4ca520;
}

.bg-yellow.box-shadow {
  box-shadow: 0px 5px 0px 0px #dcbf02;
}

.bg-pink.box-shadow {
  box-shadow: 0px 5px 0px 0px #e82d8b;
}

.bg-purple.box-shadow {
  box-shadow: 0px 5px 0px 0px #8343e8;
}

.bg-lightred.box-shadow {
  box-shadow: 0px 5px 0px 0px #d84213;
}

.bg-sky {
  background-color: #02c2c7;
}

.bg-lightblue {
  background-color: #3399ff;
}

.bg-wine {
  background-color: #9a3234;
}

.bg-green {
  background-color: #5bbd2a;
}

.bg-yellow {
  background-color: #f0d001;
}

.bg-pink {
  background-color: #ff48a4;
}

.bg-purple {
  background-color: #9d60ff;
}

.bg-lightred {
  background-color: #ff5722;
}

.bg-red {
  background-color: #e40000;
}

.padding-15px-lr {
  padding-left: 15px;
  padding-right: 15px;
}
.padding-5px-tb {
  padding-top: 5px;
  padding-bottom: 5px;
}
.margin-10px-bottom {
  margin-bottom: 10px;
}
.border-radius-5 {
  border-radius: 5px;
}

.margin-10px-top {
  margin-top: 10px;
}
.font-size14 {
  font-size: 14px;
}
.font-uppercase {
  text-transform: uppercase;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

// Map
.map {
  iframe {
    width: 100%;
    min-height: 300px;
  }
}

// Contacts
.contacts {
  .contact-block {
    .label {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 20px;
    }
    p {
      font-size: 17px;
      a {
        color: black;
      }
    }
  }
}
